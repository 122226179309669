<template>
	<div class='h100 displayFlex flexColumn'>
		<NavbarVue :showBack="true" backgroundColor="white">Back</NavbarVue>
		<div class='h100 displayFlex' style="overflow:auto">
			<!-- 左侧头像 -->
			<div class="displayFlex h100 flexAlignCenter flexJCCenter" style="width: 35%">
				<img :src="imgs[formLabelAlign.gender]" alt="" style="width: 50%">
			</div>
			<!-- 中间列表具体内容 -->
			<div class="w40">
				<!-- 列表 -->
				<div class="pl50px pt30px pb30px themeClass">
					<p class="mt30px ml100px mb30px">{{formLabelAlign.fullName}}
						<img :src="require('@/assets/img/cka/CKA.passed.png')" alt="" v-if="showCKA" width="50px" class="mt10px">
					</p>
					<el-form label-position="right" label-width="200px" :model="formLabelAlign">
						<el-form-item label="Mobile">
							{{formLabelAlign.telMobile}}
						</el-form-item>
						<el-form-item label="Email">
							{{formLabelAlign.emailAddress}}
						</el-form-item>
						<el-form-item label="Birth date">
							{{formLabelAlign.dateOfBirth}}
						</el-form-item>
						<el-form-item label="Block & Street Name">
							{{formLabelAlign.resStreet}}
						</el-form-item>
						<el-form-item label="Unit No.">
							{{formLabelAlign.resUnitNo}}
						</el-form-item>
						<el-form-item label="Building Name">
							{{formLabelAlign.resHouseNumber}}
						</el-form-item>
						<el-form-item label="Country">
							{{formLabelAlign.resCountry}}
						</el-form-item>
						<el-form-item label="Postal/Zip Code">
							{{formLabelAlign.resZipCode}}
						</el-form-item>
					</el-form>
				</div>
			</div>
			<!-- 右侧评分 -->
			<div class="w20" v-if="formLabelAlign.grade">
				<div class='h100 displayFlex flexColumn  flexAlignCenter flexJCCenter gradeBox'>
					<p style="font-size: 28px;text-align: center;">{{formLabelAlign.grade}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NavbarVue from "../../components/Navbar.vue";
import { ClientView } from 'api/My'
export default {
	components: { NavbarVue },
	data () {
		return {
			theme: '',
			content: '',
			NotificationList: [],
			formLabelAlign: {},
			levels: [
				{
					code: 'C1',
					value: 'Conservative'
				},
				{
					code: 'C2',
					value: 'Moderately Conservative'
				},
				{
					code: 'C3',
					value: 'Balanced'
				},
				{
					code: 'C4',
					value: 'Moderately Aggressive'
				},
				{
					code: 'C5',
					value: 'Aggressive'
				}
			],
			imgs: {
				Male: require('@/assets/img/avatar/avatar.mr.lg.png'),
				Female: require('@/assets/img/avatar/avatar.mrs.lg.png'),
			},
			showCKA: false
		};
	},
	computed: {},
	watch: {},
	methods: {
		async getList () {
			let res = await ClientView({ clientNumber: this.$router.currentRoute.query.code })
			this.formLabelAlign = res.content
			this.showCKA = this.formLabelAlign.assessment_outcome && (this.formLabelAlign.assessment_outcome.includes('1') || this.formLabelAlign.assessment_outcome.includes('2'))
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.getList()
	},
	beforeCreate () { }, //生命周期 - 创建之前
	beforeMount () { }, //生命周期 - 挂载之前
	beforeUpdate () { }, //生命周期 - 更新之前
	updated () { }, //生命周期 - 更新之后
	beforeDestroy () { }, //生命周期 - 销毁之前
	destroyed () { }, //生命周期 - 销毁完成
	activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='css' scoped>
.themeClass {
	color: #707070;
}
.el-form >>> .el-form-item__label {
	font-size: 18px;
}
.el-form >>> .el-form-item__content {
	font-size: 18px;
	color: #080809;
	margin-left: 230px !important;
}
.themeClass p {
	color: #646464;
	font-size: 40px;
}
.gradeBox {
	width: 300px;
	height: 300px;
	border: 10px solid #f74323;
	font-size: 86px;
	color: #f74323;
	font-weight: bold;
	margin-top: 30%;
}
</style>